<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title class="d-flex justify-space-between mb-3">
          Franchises
          <div class="d-flex justify-center align-center">
            <v-text-field
              class="mr-4"
              label="Search"
              append-icon="mdi-magnify"
              v-model="search"
              hide-details
              outlined
              dense
            ></v-text-field>
            <v-btn
              color="primary"
              @click="$router.push({ name: 'create-franchise' })"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
          </div>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="getFranchises"
          :loading="getFranchisesLoading"
          item-key="id"
          class="elevation-1 data-table"
          multi-sort
        >
          <template v-slot:item.action="{ item }">
            <v-menu origin="bottom center" :offset-y="true" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">
                  Manage
                  <v-icon right class="ml-0">mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list dense class="py-1">
                <v-list-item
                  @click="$router.push(`/app/franchise/${item.id}/edit`)"
                >
                  <v-list-item-icon class="mr-1">
                    <v-icon dense>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="$router.push(`/app/franchise/${item.id}/customers`)"
                >
                  <v-list-item-icon class="mr-1">
                    <v-icon dense>mdi-account-group</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Customers</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="showRemoveDialog(item.id)">
                  <v-list-item-icon class="mr-1">
                    <v-icon dense>mdi-trash-can</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </base-card>
      <template v-if="selected">
        <v-row justify="center">
          <v-dialog max-width="350" v-model="removeDialog">
            <v-card>
              <v-card-title>Delete franchise</v-card-title>
              <v-card-text>
                The user will be deleted permanently. Are you sure?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="removeDialog = false">Cancel</v-btn>
                <v-btn color="danger" text @click="remove">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Franchises",
  },
  data() {
    return {
      search: "",
      selected: null,
      headers: [
        { text: "Username", value: "username", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      removeDialog: false,
    };
  },
  mounted() {
    this.setFranchises();
  },
  methods: {
    ...mapActions(["setFranchises", "deleteFranchise", "setFranchisesLoading"]),
    showRemoveDialog(id) {
      this.selected = id;
      this.removeDialog = true;
    },
    async remove() {
      if (this.selected) {
        try {
          this.setFranchisesLoading(true);
          const { data } = await this.deleteFranchise(this.selected);
          this.$store.dispatch("showSnackbar", data);
          this.selected = null;
          this.removeDialog = false;
          this.setFranchises();
        } catch (error) {
          console.error(error);
          this.$store.dispatch("showSnackbar", "Something went wrong.");
        }

        this.setFranchisesLoading(false);
        this.setFranchises();
      }
    },
  },
  computed: {
    ...mapGetters(["getFranchises", "getFranchisesLoading"]),
  },
};
</script>
<style lang="scss" scoped></style>
